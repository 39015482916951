import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy Policy`}</h1>
    <p>{`Mondaic AG (“us”, “we”, or “our”) develops and distributes the Salvus
Software Suite and operates the website
`}<a parentName="p" {...{
        "href": "https://www.mondaic.com"
      }}>{`https://www.mondaic.com`}</a>{` that also hosts the license server and download tool
(hereinafter collectively referred to as “Services”). This page informs you
of our policies regarding the collection, use and disclosure of personal data
when you use our Services and the choices you have associated with that data.
We use your data to provide and improve the Service. By using the Service,
you agree to the collection and use of information in accordance with this
Privacy Policy.`}</p>
    <h2>{`Data collection`}</h2>
    <h3>{`What data do we collect?`}</h3>
    <h4>{`Personal data`}</h4>
    <p>{`While using our Services, we may ask you to provide us with certain
personally identifiable information that can be used to contact or identify
you (“Personal Data”). Personally identifiable information may include, but
is not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Email address`}</li>
      <li parentName="ul">{`First name and last name`}</li>
      <li parentName="ul">{`Phone number`}</li>
      <li parentName="ul">{`Address, State, Province, ZIP/Postal code, City`}</li>
    </ul>
    <h4>{`Usage data`}</h4>
    <p>{`To use the Services, communication with the Mondaic's license
server is required to validate the license. In this process, we may collect
information on how the Service is accessed and used. Collected data includes:`}</p>
    <ul>
      <li parentName="ul">{`User name and password which is linked to your personal account information.`}</li>
      <li parentName="ul">{`The time and date of your request.`}</li>
      <li parentName="ul">{`IP addresses and operating system from computers you are using to connect to
the license server.`}</li>
      <li parentName="ul">{`Which software product from Mondaic AG you are using, on how many processes
you are using it, and for how long you are using it.`}</li>
      <li parentName="ul">{`Only when using a license token: A non-reversible hash/fingerprint of input
files used to control software packages of Mondaic AG.`}</li>
      <li parentName="ul">{`Only when using a license token: A non-reversible hash/fingerprint of the
machine used to run software from Mondaic AG.`}</li>
    </ul>
    <h3>{`How do we collect your data?`}</h3>
    <p>{`You directly provide data to us when you`}</p>
    <ul>
      <li parentName="ul">{`Register a user account to obtain a license file,`}</li>
      <li parentName="ul">{`Get in touch with us by email, use our support channels and message boards,
or voluntarily complete a customer survey`}</li>
      <li parentName="ul">{`Use or view our website via your browser’s cookies.
In addition, we collect and process data when you send requests to the Salvus
license server. This happens when importing the Salvus python packages or
when launching simulations or inversions.`}</li>
    </ul>
    <h3>{`What are cookies?`}</h3>
    <p>{`Cookies are files with a small amount of data which may include an anonymous
unique identifier. Cookies are sent to your browser from a website and stored
on your device. We use cookies and similar tracking technologies to track the
activity on our website and to improve and analyze our service.`}</p>
    <h2>{`How will we use your data?`}</h2>
    <p>{`Mondaic AG collects your data so that we can`}</p>
    <ul>
      <li parentName="ul">{`Manage your account`}</li>
      <li parentName="ul">{`Provide, maintain and improve our Services to you`}</li>
      <li parentName="ul">{`Notify you about available updates and patches`}</li>
      <li parentName="ul">{`Process and validate your requests to the license server`}</li>
      <li parentName="ul">{`Monitor the usage of our Services`}</li>
      <li parentName="ul">{`Offer customer support and technical advice`}</li>
      <li parentName="ul">{`Detect, prevent, and address technical issues`}</li>
    </ul>
    <h2>{`Service Providers`}</h2>
    <p>{`We may employ third party companies and individuals to facilitate our Service
(“Service Providers”), or assist us in analyzing how our Service is used.
These third parties have access to your Personal Data only to perform these
tasks on our behalf and are obligated not to disclose or use it for any other
purpose. Currently, we use Google Analytics, a web analytics service offered
by Google that tracks and reports website traffic. For more information on
the privacy practices of Google, please visit the `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy?hl=en"
      }}>{`Google Privacy & Terms web
page`}</a>{`. Furthermore, we work with
Stripe to process invoices payed by credit card. Their Privacy Policy can be
viewed `}<a parentName="p" {...{
        "href": "https://stripe.com/privacy"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Security`}</h2>
    <p>{`We value your trust in providing us your Personal Information, thus we are
striving to use commercially acceptable means of protecting it. But remember
that no method of transmission over the internet, or method of electronic
storage is totally secure and reliable, and we cannot guarantee its absolute
security.`}</p>
    <h2>{`Links to other sites`}</h2>
    <p>{`Our Service may contain links to other sites. If you click on a third-party
link, you will be directed to that site. Note that these external sites are
not operated by us. Therefore, we strongly advise you to review the Privacy
Policy of these websites. We have no control over, and assume no
responsibility for the content, privacy policies, or practices of any
third-party sites or services. Our Service may contain links to other sites
that are not operated by us. If you click on a third-party link, you will be
directed to that site. We have no control over, and assume no responsibility
for the content, privacy policies or practices of any third party sites or
services. We strongly advise you to review the Privacy Policy of every site
you visit.`}</p>
    <h2>{`Children’s privacy`}</h2>
    <p>{`Our Services do not address anyone under the
age of 18. We do not knowingly collect personal identifiable information from
children under 18. In the case we discover that a child under 18 has provided
us with personal information, we immediately delete this from our servers. If
you are a parent or guardian and you are aware that your child has provided
us with personal information, please contact us so that we will be able to do
necessary actions.`}</p>
    <h2>{`Changes to our Privacy Policy`}</h2>
    <p>{`This Privacy Policy may be updated from time to time and without prior notice
to you to reflect changes in our information practices. Changes to this
Privacy Policy are effective when they are posted on this page. We will
indicate at the bottom of this Privacy Policy when it was most recently
updated. You are encouraged to review this Privacy Policy periodically for
the latest information on our privacy practices.`}</p>
    <h2>{`Contact us`}</h2>
    <p>{`In case you have any questions or comments about this Privacy Policy please
get in touch with us:`}</p>
    <ul>
      <li parentName="ul">{`Send an email to `}<a parentName="li" {...{
          "href": "mailto:info@mondaic.com"
        }}>{`info@mondaic.com`}</a>{` or`}</li>
      <li parentName="ul">{`Get in touch with us using our `}<a parentName="li" {...{
          "href": "/impressum"
        }}>{`contact information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      